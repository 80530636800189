import React, { Component } from 'react';
import './App.css';
import TableMain from './components/pivottable';
import { fetchExperiments } from './api';

// import DATA from './json/data.flat.json';
class App extends Component {
  state = { data: [], error: null }

  async componentDidMount() {
    try {
      const res = await fetchExperiments();
      this.setState({ data: res, error: false })
    } catch (error) {
      this.setState({ error: error.message })
      console.log(error)
    }
  }
  
  render() { 
    if (this.state.error) {
      return <h4>Error occured. Please check console</h4>
    }
    if (this.state.data.length === 0) {
      return <img
          style={{margin: '20px auto', display: 'block'}}
          width="24"
          height="24"
          src="https://i.pinimg.com/originals/3e/f0/e6/3ef0e69f3c889c1307330c36a501eb12.gif"
      />
    }
    return ( 
      <TableMain data={this.state.data}/>
    );
  }
}

export default App;

import React from 'react';
import {
  BrowserRouter, Route, Switch,
  // Link, NavLink
} from 'react-router-dom';
import App from './App'
const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={App} exact={true}/>
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
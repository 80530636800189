const axios = require("axios");
const fs = require('fs');
const NEO4J_API = "https://neo4j.targetepigenomics.org/db/data/transaction/commit"; // PROD
// const NEO4J_API = "http://178.128.154.182:7474/db/data/transaction/commit";      // TEST
const AUTHORIZATION = "Basic bmVvNGo6cHJvZHVjdGlvbg==";

const TRANSLATIONS = {
  'Blood UBERON:0000178': 'Blood',
  'RNA-seq (OBI:0001271)': 'RNA-seq',
  'Liver UBERON:0002107': 'Liver',
  'Lung UBERON: 0002048': 'Lungs',
  'Heart UBERON:0000948': 'Heart',
  'ATAC-seq (transposase-accessible chromatin, OBI:0002039)': 'ATAC-seq',
  'TBT (Tributyltin chloride) CHEBI:79734': 'TBT',
  'As (arsenite(3-)) CHEBI:29866': 'Arsenite',
  'Control Drinking Water CHEBI:15377': 'Control Drinking Water',
  'TCDD (2,3,7,8-tetrachlorodibenzo-p-dixoin) CHEBI:28119': 'TCDD',
  'BPA (Bisphenol A) CHEBI:33216': 'BPA',
  'DEHP (bis (2-ethylhexyl) phthalate) CHEBI:17747': 'DEHP',
  'Pb CHEBI:33112': 'Pb',
  'PM2.5 ENVO:01000415': 'PM',
  'RRBS (reduced representation bisulfite sequencing, OBI:0001862)': 'RRBS-Seq'
};
/**
 * 
 * @param {Array} files 
 * @param {Boolean} undo  default: false, reverse : true means undo deprecate
 */
export async function fetchExperiments() {
  try {
      const res = await neo4jPost(QUERY);
      const parsedNeo4jOutput =  injestNeo4jDump(res);
      const flattenedOutput = flattenNeo4jOutput(parsedNeo4jOutput);
      return flattenedOutput;
  } catch (e) {
    console.error(e); // 💩
  }
}


async function neo4jPost() {
  try {
    const neo4jRes = await axios.post(NEO4J_API, {
      statements: [
        {
          statement: QUERY,
          parameters: {}
        }
      ]
    }, {
      headers: {
        Authorization: AUTHORIZATION
      }
    })

    return neo4jRes.data.results[0];
  } catch (error) {
    console.log(error)
  }
  
}


function injestNeo4jDump(input) {
  const { data, columns } = input;
  const result = data.map((line, index) => {
    const { row } = line;
    let lineResult = {};
      row.forEach((item, index) => {
          if (columns[index]) {
              lineResult[columns[index]] = TRANSLATIONS[item] || item;
          } else {
              lineResult[columns[index]] = TRANSLATIONS[item] || item;
          }
      })
      return lineResult;
  })
  return result;
}

function flattenNeo4jOutput(input) {
const all = [];
let rowCounts = {};
input.forEach((miceGroup, mgIndex) => {
    const { Lab, Exposure, Dose, Tissue, Age, Assay, Sex } = miceGroup
    const sortedExperiments = miceGroup.experiments.sort(compareValues('mouse'));

    const REPLICATESET = generateAccession('ES', 7);
    sortedExperiments
        .forEach((experiment, BR) => {
            const {mouse, biosamples, mouse_details} = experiment;
            biosamples.forEach((entry, biosampleIndex) => {
                const {biosample, assays, biosample_details} = entry;
                assays.forEach((elem, assayIndex) => {
                    const {assay, files, assay_details} = elem;
                    files.forEach((file, fileIndex) => {
                        const { uuid, score, accession, paired_file_uuid, paired_file_accession, submission, status } = file;

                        const EXPERIMENT = generateAccession('EXP', 7);
                        const tmp = {
                            experiment: EXPERIMENT,
                            experiment_set: REPLICATESET,
                            br: `${BR+1}`,
                            bstr: `${biosampleIndex+1}`,
                            astr: `${assayIndex+1}`,
                            sr: `${fileIndex+1}`,
                            mouse: mouse,
                            biosample: biosample,
                            assay: assay,
                            uuid, score, accession, paired_file_uuid, paired_file_accession, submission, status,
                            Lab, Exposure, Dose, Tissue, Age, Assay, Sex
                        };
                        const combined = {...tmp, ...mouse_details, ...biosample_details, ...assay_details};
                        all.push(combined);

                        if (rowCounts.hasOwnProperty(REPLICATESET)) {
                            if (rowCounts[REPLICATESET].hasOwnProperty(mouse)) {
                                rowCounts[REPLICATESET][mouse] = rowCounts[REPLICATESET][mouse] + 1
                            } else {
                                rowCounts[REPLICATESET][mouse] = 1
                            }
                            if (rowCounts[REPLICATESET].hasOwnProperty(biosample)) {
                                rowCounts[REPLICATESET][biosample] = rowCounts[REPLICATESET][biosample] + 1
                            } else {
                                rowCounts[REPLICATESET][biosample] = 1
                            }
                            if (rowCounts[REPLICATESET].hasOwnProperty(assay)) {
                                rowCounts[REPLICATESET][assay] = rowCounts[REPLICATESET][assay] + 1
                            } else {
                                rowCounts[REPLICATESET][assay] = 1
                            }
                        } else {
                            rowCounts[REPLICATESET] = {};
                            rowCounts[REPLICATESET][mouse] = 1;
                            rowCounts[REPLICATESET][biosample] = 1
                            rowCounts[REPLICATESET][assay] = 1
                        }
                    })
                })
            })
        })
})


const updatedAll = all.map(line => {
    let lineClone = {...line};
    const { experiment_set, mouse, biosample, assay, bstr, astr, sr } = line;

    if (bstr === '1' && astr === '1') {
        // if (sr !== '1') {
            lineClone['biosampleRowSpan'] = rowCounts[experiment_set][biosample]
            lineClone['assayRowSpan'] = rowCounts[experiment_set][assay]
            lineClone['mouseRowSpan'] = rowCounts[experiment_set][mouse]
        // }
    }

    if (bstr !== '1' && astr === '1') {
        // if (rowCounts[experiment_set][biosample] > 1) {
            lineClone['biosampleRowSpan'] = rowCounts[experiment_set][biosample]
            lineClone['assayRowSpan'] = rowCounts[experiment_set][assay]
        // }
    }

    if (astr !== '1') {
        if (rowCounts[experiment_set][assay]) {
            lineClone['assayRowSpan'] = rowCounts[experiment_set][assay]
        }
    }
    return lineClone;
})

return updatedAll;
}

function generateAccession(prefix, length) { //prefix = 'BR | EXP | TR | SR'
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ";
    var randomstring = '';
    for (var i=0; i < length; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum,rnum+1);
    }
    const accession = "TGT" + prefix + randomstring;
    return accession;
}

function flatterArrayOfArrays(incoming) {
    const flattened = incoming.reduce(function (accumulator, currentValue) {
        return accumulator.concat(currentValue);
    }, []);

    return flattened;
}


function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) ||
          !b.hasOwnProperty(key)) {
          return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
        (comparison * -1) : comparison
      );
    };
}

const QUERY = `
MATCH (f1:file)-[:paired_file]->(f:file { pair: "forward"})-->(a:assay)-->(b:biosample)-->(m:mouse)-->(t:treatment) 
MATCH (f)-[:tagged]->(tag:filetag)
WHERE f.pilot = "production"
WITH DISTINCT f.lab AS lab,
f.submission_id as Submission,
f.accession as file_accession,
f.file_uuid as file_uuid,
f.filename as filename,
f1.accession as paired_file_accession,
f1.file_uuid as paired_file_uuid,
tag.score as score,
t.exposure_type as exposure_type, 
t.exposure_specific AS exposure, 
t.exposure_dose AS dose, 
b.accession as biosample, 
b.tissue AS tissue, 
a.technique AS assay_technique, 
m.sex AS mouse_gender,
m.accession as mouse, 
a.accession as assay, 
t.exposure_category as treatment_exposure_category,
t.exposure_age_last as treatment_exposure_age_last,
t.exposure_specific as treatment_exposure_specific,
t.exposure_life_stage as treatment_exposure_life_stage,
t.exposure_age_first	 as treatment_exposure_age_first, 
t.exposure_paradigm as treatment_exposure_paradigm,
a.strand_specificity	as assay_strand_specificity,
a.starting_nucleic_acid as assay_starting_nucleic_acid,
a.category as assay_category,
a.assay_protocol as assay_protocol_url,
b.specific_tissue as biosample_specific_tissue,
b.collection_protocol as biosample_collection_protocol_url,
m.fasted as mouse_fasted,
m.fasted_hours as mouse_fasted_hours,
m.strain as mouse_strain,
m.life_stage_collection as mouse_life_stage_collection,
m.perfusion	 as mouse_perfusion,
m.internal_id as mouse_internal_id,
m.animal_weight_sac as mouse_animal_weight_sac,
m.liver_tumors as mouse_liver_tumors,
m.tumor_organs	 as mouse_tumor_organs,
m.mouse_age_collection as mouse_age_collection_precise,
CASE
WHEN m.mouse_age_collection <=3
    THEN '3 weeks'
ELSE '5 months' END AS age_of_mice,
CASE
WHEN a.technique = 'RNA-seq (OBI:0001271)' AND tag.score > 2
    THEN 'PASS'
WHEN a.technique = 'ATAC-seq (transposase-accessible chromatin, OBI:0002039)' AND tag.score > 4
    THEN 'PASS'
ELSE 'FAIL' END AS qcstatus
WITH DISTINCT lab, exposure_type, exposure, dose, biosample, tissue, age_of_mice, assay_technique, mouse_gender, mouse, assay, 
treatment_exposure_category,
treatment_exposure_age_last,
treatment_exposure_specific,
treatment_exposure_life_stage,
treatment_exposure_age_first, 
treatment_exposure_paradigm,
assay_strand_specificity,
assay_starting_nucleic_acid,
assay_category,
assay_protocol_url,
biosample_specific_tissue,
biosample_collection_protocol_url,
mouse_fasted,
mouse_fasted_hours,
mouse_strain,
mouse_life_stage_collection,
mouse_perfusion,
mouse_internal_id,
mouse_animal_weight_sac,
mouse_liver_tumors,
mouse_tumor_organs,
    collect({accession:file_accession, 
                submission: Submission, 
                uuid:file_uuid, paired_file_accession: paired_file_accession, paired_file_uuid: paired_file_uuid, 
                score: score, status: qcstatus, filename: filename}) AS files 
WITH DISTINCT lab,exposure_type,exposure,dose,biosample,tissue,age_of_mice,assay_technique,mouse_gender,mouse,
treatment_exposure_category,
treatment_exposure_age_last,
treatment_exposure_specific,
treatment_exposure_life_stage,
treatment_exposure_age_first, 
treatment_exposure_paradigm,
biosample_specific_tissue,
biosample_collection_protocol_url,
mouse_fasted,
mouse_fasted_hours,
mouse_strain,
mouse_life_stage_collection,
mouse_perfusion,
mouse_internal_id,
mouse_animal_weight_sac,
mouse_liver_tumors,
mouse_tumor_organs,
    collect({assay:assay,files:files, 
		assay_details: { assay_strand_specificity: assay_strand_specificity,
					assay_starting_nucleic_acid: assay_starting_nucleic_acid,
					assay_category: assay_category,
					assay_protocol_url: assay_protocol_url}
	}) AS assays
WITH DISTINCT lab,exposure_type,exposure,dose,tissue,age_of_mice,assay_technique,mouse_gender,mouse,
treatment_exposure_category,
treatment_exposure_age_last,
treatment_exposure_specific,
treatment_exposure_life_stage,
treatment_exposure_age_first, 
treatment_exposure_paradigm,
biosample_specific_tissue,
biosample_collection_protocol_url,
mouse_fasted,
mouse_fasted_hours,
mouse_strain,
mouse_life_stage_collection,
mouse_perfusion,
mouse_internal_id,
mouse_animal_weight_sac,
mouse_liver_tumors,
mouse_tumor_organs,
    collect({biosample:biosample,assays:assays,
		biosample_details: {
				biosample_specific_tissue: biosample_specific_tissue,
				biosample_collection_protocol_url: biosample_collection_protocol_url
			}
		}) as biosamples
RETURN DISTINCT lab as Lab, exposure as Exposure, dose as Dose, tissue as Tissue, age_of_mice as Age, assay_technique as Assay, mouse_gender as Sex, 
    collect({mouse:mouse,biosamples:biosamples,
					mouse_details: {
						treatment_exposure_category: treatment_exposure_category,
						treatment_exposure_age_last: treatment_exposure_age_last,
						treatment_exposure_specific: treatment_exposure_specific,
						treatment_exposure_life_stage: treatment_exposure_life_stage,
						treatment_exposure_age_first: treatment_exposure_age_first, 
						treatment_exposure_paradigm: treatment_exposure_paradigm,
						mouse_age_precise: age_of_mice,
						mouse_fasted: mouse_fasted,
						mouse_fasted_hours: mouse_fasted_hours,
						mouse_strain: mouse_strain,
						mouse_life_stage_collection: mouse_life_stage_collection,
						mouse_perfusion: mouse_perfusion,
						mouse_internal_id: mouse_internal_id,
						mouse_animal_weight_sac: mouse_animal_weight_sac,
						mouse_liver_tumors: mouse_liver_tumors,
						mouse_tumor_organs: mouse_tumor_organs
					}
}) as experiments, LENGTH(collect({mouse:mouse,biosamples:biosamples})) as count
ORDER BY Lab, Exposure, Dose, Tissue, Age, Assay
`;

// ORDER BY lab, exposure, dose, tissue, age_of_mice, assay_technique